import PropTypes from 'prop-types'
import React from 'react'
import { Box, Grid, Heading } from 'theme-ui'
import ProductCard from '~/components/Product/Card'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const renderGridItems = items => {
  return items.map(item => {
    switch (item.__typename) {
      case 'ContentfulProduct':
        return <ProductCard key={item.slug} product={item} />

      default:
        console.warn(`Oh snap! ${item.__typename} model is not supported yet.`)
        return null
    }
  })
}

const RelatedProducts = ({ products = [] }) => {
  const translate = useTranslate()
  return (
    <Box
      sx={{
        textAlign: 'center',
        marginTop: ['32px', null, '60px'],
        marginBottom: ['16', null, '32ppx']
      }}
    >
      <Heading
        as="h2"
        variant="h2"
        sx={{
          marginBottom: ['16px', '32px', '60px']
        }}
      >
        {translate('product.related_products')}
      </Heading>
      <Grid
        columns={[1, 2, 3]}
        gap={['0.75rem', '2.25rem']}
        p={['0 0.5rem 2rem 0.5rem', '0 0 6.25rem 0']}
      >
        {renderGridItems(products)}
      </Grid>
    </Box>
  )
}

RelatedProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}))
}

export default RelatedProducts
