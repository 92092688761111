/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import CollapsiblePanel from '~/components/Generic/CollapsiblePanel'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const ProductDirections = ({ product, initiallyCollapsed = true }) => {
  const translate = useTranslate()

  const { slug, directions } = product
  return (
    <Flex
      sx={{
        border: '1px solid',
        backgroundColor: 'white',
        py: 10,
        minHeight: ['72px', '80px'],
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
      }}
    >
      <CollapsiblePanel
        title={translate('product.how_to_use')}
        initiallyCollapsed={initiallyCollapsed}
        productHandle={slug}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: directions.childMarkdownRemark.html
          }}
        />
      </CollapsiblePanel>
    </Flex>
  )
}

ProductDirections.propTypes = {
  product: PropTypes.shape({})
}

export default ProductDirections
