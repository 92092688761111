/** @jsx jsx */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductDirections from '~/components/Product/Directions'
import ProductIngredients from '~/components/Product/Ingredients'
import ProductOptions from '~/components/Product/Options'
import ProductQuantitySelector from '~/components/Product/QuantitySelector'
import useProductVariants from '~/hooks/components/use-product-variants'

const ProductDetails = ({
  product,
  collectionName = null,
  handleVariantChange,
  ...props
}) => {
  const { longDescription, variants, optionTypes } = product

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const { currentVariant, selectVariant } = useProductVariants(variants)

  // get all the available choices from the variants
  const allVariantOptions = variants.flatMap(v => v.optionValues)

  const uniqueVariantOptions = allVariantOptions.reduce((acc, option = {}) => {
    return acc.find(o => o.slug === option.slug) ? acc : [...acc, option]
  }, [])

  // build a list of option types, restricting choices to those available via our variants
  const allowedOptionValues = optionTypes
    ? optionTypes.map(optionType => ({
        name: optionType.name,
        optionValues: uniqueVariantOptions.reduce((acc, option = {}) => {
          return option.option_type[0].slug === optionType.slug
            ? [...acc, option]
            : acc
        }, []),
        presentation: optionType.presentation,
        slug: optionType.slug
      }))
    : []

  const { sku, shopifyId, name, size, price, regularPrice } = currentVariant

  useEffect(() => {
    handleVariantChange(currentVariant)
  }, [currentVariant, handleVariantChange])

  return (
    <Box
      {...props}
      sx={{
        textAlign: ['center', null, 'left'],
        margin: 'auto',
        marginTop: ['8px', null, '48px'],
        maxWidth: ['650px !important', null, '50% !important']
      }}
    >
      {collectionName && (
        <Heading
          as="h3"
          variant="navLink"
          color="primary"
          sx={{
            variant: 'text.navLink',
            marginTop: ['16px', null, '0'],
            marginBottom: ['0', null, '12px']
          }}
        >
          {collectionName}
        </Heading>
      )}
      <Heading as="h1" variant="h2">
        {name}
      </Heading>

      <Heading
        as="h6"
        variant="navLink"
        sx={{
          mt: ['8px', null, '12px'],
          mb: ['8px', null, '20px']
        }}
      >
        {size}
      </Heading>

      <Flex
        sx={{
          flexDirection: 'column'
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            textAlign: 'left'
          }}
        >
          {optionTypes && (
            <ProductOptions
              allOptions={allowedOptionValues}
              currentValues={currentVariant.optionValues}
              handleChange={option => selectVariant(option)}
            />
          )}
        </Flex>

        <Flex
          py="3"
          sx={{
            display: 'inline-flex'
          }}
        >
          <ProductQuantitySelector
            handleChange={value => setSelectedQuantity(parseInt(value))}
          />
          <ProductAddToCartButton
            sx={{
              marginLeft: ['12px', null, '16px']
            }}
            price={price}
            quantity={selectedQuantity}
            regularPrice={regularPrice}
            sku={sku}
            variantId={shopifyId}
            placement="ProductDetailsPage"
            position={1}
          />
        </Flex>
      </Flex>

      {longDescription && (
        <div
          sx={{
            '& p': {
              marginTop: '8px',
              marginBottom: '28px'
            }
          }}
          dangerouslySetInnerHTML={{
            __html: longDescription.childMarkdownRemark.html
          }}
        />
      )}

      {product && product.ingredients && (
        <ProductIngredients product={product} />
      )}

      {product && product.directions && <ProductDirections product={product} />}
    </Box>
  )
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.shape({}),
    longDescription: PropTypes.shape({}),
    ingredients: PropTypes.shape({}),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    relatedProducts: PropTypes.array,
    size: PropTypes.string,
    sku: PropTypes.string,
    quote: PropTypes.shape({}),
    directions: PropTypes.shape({}),
    collectionName: PropTypes.string
  }),
  handleVariantChange: PropTypes.func.isRequired
}

export default ProductDetails
