/** @jsx jsx */
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'

const Thumbnail = ({ image, handleClick, selected = false }) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        opacity: selected ? 1 : 0.5,
        borderWidth: selected ? 1 : 0,
        borderColor: selected ? 'primary' : '',
        borderStyle: selected ? 'solid' : 'none',
        '&:hover': {
          opacity: 1
        }
      }}
      onClick={() => handleClick(image)}
    >
      <GatsbyImage key={image.id} alt={image.title} image={getImage(image)} />
    </Box>
  )
}

Thumbnail.propTypes = {
  /**
   * A gatsby-plugin-image object.
   */
  image: PropTypes.shape({}),
  /**
   * The click event handler
   */
  handleClick: PropTypes.func.isRequired,
  /**
   * Is the thumbnail active?
   */
  selected: PropTypes.bool
}

export default Thumbnail
