/** @jsx jsx */
import { Fragment, useState, useEffect } from 'react'
import { Box, Container, Flex, jsx } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import RelatedProducts from '~/components/Generic/RelatedProducts'
import ProductDetails from '~/components/Product/Details'
import ProductImages from '~/components/Product/Images'
import ProductQuote from '~/components/Product/Quote'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import { encodeShopifyVariantID } from '~/utils/shopify'

const ProductPage = ({ product }) => {
  const { slug, relatedProducts, quote, variants } = product
  const { trackProductViewed, trackVariantClicked } = useAnalytics()

  // each product have at least one variant (enforced by Contentful model)
  const [variant, setVariant] = useState(variants[0])

  const images = [variant.mainImage]

  const uniqueImages = images.reduce((acc, image = {}) => {
    return acc.find(item => item.id === image.id) ? acc : [...acc, image]
  }, [])

  const collection = product.collection.find(collection => collection)

  useEffect(() => {
    trackProductViewed({ productHandle: slug })
  }, [trackProductViewed, slug])

  return (
    <Fragment>
      <Container p={0}>
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            marginTop: ['0', null, '32px']
          }}
        >
          <Box
            sx={{
              variant: 'responsive.mobileTablet',
              width: ['92%', '60%'],
              margin: 'auto',
              '& .carousel-slider': {
                overflow: 'visible'
              },
              '& .slider-wrapper': {
                overflow: 'visible'
              }
            }}
          >
            <ImageCarousel images={uniqueImages} />
          </Box>
          <Box sx={{ variant: 'responsive.desktop', width: '100%' }}>
            <ProductImages images={uniqueImages} />
          </Box>
          <ProductDetails
            product={product}
            collectionName={collection.slug}
            sx={{
              maxWidth: ['100%', '100%', '50%'],
              paddingLeft: ['1rem', null, '2rem'],
              paddingRight: ['1rem', null, '2rem']
            }}
            handleVariantChange={v => {
              if (v.shopifyId !== variant.shopifyId) {
                trackVariantClicked({
                  variantId: encodeShopifyVariantID(v.shopifyId)
                })
                setVariant(v)
              }
            }}
          />
        </Flex>
      </Container>
      <Container variant="fullWidth">
        {quote && <ProductQuote quote={quote} />}
      </Container>
      <Container>
        {relatedProducts && <RelatedProducts products={relatedProducts} />}
      </Container>
    </Fragment>
  )
}

export default ProductPage
